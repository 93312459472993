import { Controller } from "stimulus";
import {get, set} from 'tiny-cookie';

export const SELECTOR_DISCLOSURE = "[aria-expanded]";

const STORAGE_KEY = "cs-collapsible-sidebar-collapsed";

export default class extends Controller {

  static values = {
    collapsed: Boolean
  }
  static targets = [ "button" ]

  initialize() {
    // The sidebar needs to be expanded when clicking on disclosure triggers
    // so users can see the submenus
    this.element.addEventListener('click', (event) => {
      const toggle = event.target.closest(SELECTOR_DISCLOSURE)
      if (!this.ignoreDisclosureClicks && toggle) {
        // We need to keep track of which disclosure is being clicked
        // so as not to click it again when uncollapsing the navbar
        // which would toggle its state again
        this.disclosureToggle = toggle;
        this.open();
        this.disclosureToggle = null;
      }
    });
  }

  connect() {
    this.waitForTippyToInitThenDisableIfNotCollapsed();
  }

  waitForTippyToInitThenDisableIfNotCollapsed() {
    this.refreshTimer = setInterval(() => {
      let collpasedSideBarCookieSet = get(STORAGE_KEY) != "true";
      if (collpasedSideBarCookieSet) {
        const navItems = document.getElementsByClassName("nav-item");
        Array.from(navItems).forEach((navItem) => {
          if (navItem._tippy) {
            this.disableTippyOnNavItems();
            this.stopRefreshing();
          }
        });
      } else {
        this.stopRefreshing();
      }
    }, 100);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }

  toggle() {
    if (this.collapsedValue) {
      this.open();
    } else{
      this.close();
    }
  }

  open() {
    this.collapsedValue = false;
    this.maybeReopendisclosures();
    this.disableTippyOnNavItems();
    this.buttonTarget.setAttribute("aria-label", "Close the sidebar menu")
  }

  close() {
    this.collapsedValue = true;
    this.closeOpenDisclosures();
    this.showTippyOnNavItems();
    this.buttonTarget.setAttribute("aria-label", "Open the sidebar menu")
  }

  collapsedValueChanged(value) {
    this.saveState(value);
  }

  closeOpenDisclosures(){
    this.whileIgnoringDisclosureClicks(() => {
      [...this.element.querySelectorAll(SELECTOR_DISCLOSURE)].forEach(
        (disclosureToggle) => {
          // The `aria-expanded="true"` attribute marks open disclosures
          if (disclosureToggle.getAttribute("aria-expanded") == "true") {
            disclosureToggle.click();
            // We'll need to restore the disclosure state if reop
            disclosureToggle.setAttribute("data-was-aria-expanded", "true");
          }
        }
      );
    });
  }

  maybeReopendisclosures() {
    this.whileIgnoringDisclosureClicks(() => {
      [...this.element.querySelectorAll(SELECTOR_DISCLOSURE)].forEach(
        (disclosureToggle) => {
          if (disclosureToggle.getAttribute("data-was-aria-expanded") == "true") {
            console.log('Expanding', disclosureToggle, this.disclosureToggle, this.disclosureToggle === disclosureToggle);
            if (disclosureToggle !== this.disclosureToggle) {
              disclosureToggle.click();
            }
            disclosureToggle.removeAttribute("data-was-aria-expanded");
          }
        }
      );
    });
  }

  showTippyOnNavItems() {
    const recordButtons = document.getElementsByClassName("record-session-button--pill");
    Array.from(recordButtons).forEach((button) => {
      button._tippy?.enable();
    });
    const navItems = document.getElementsByClassName("nav-item");
    Array.from(navItems).forEach((navItem) => {
      navItem._tippy?.enable();
    });
  }

  disableTippyOnNavItems() {
    const recordButtons = document.getElementsByClassName("record-session-button--pill");
    Array.from(recordButtons).forEach((button) => {
      button._tippy?.disable();
    });
    const navItems = document.getElementsByClassName("nav-item");
    Array.from(navItems).forEach((navItem) => {
      navItem._tippy?.disable();
    });
  }

  // When automatically clicking on disclosure elements to close them
  // when collapsing or uncollapsing, we need to ignore the clicks
  // so we don't re-open the sidebar
  whileIgnoringDisclosureClicks(fn) {
    this.ignoreDisclosureClicks = true;
    fn();
    this.ignoreDisclosureClicks = false;
  }

  saveState(value) {
    set(STORAGE_KEY, value)
  }
}
